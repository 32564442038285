export const setLogoOffset = () => {
  if (process.server) return;
  const logoContainer = document.querySelector(".logo-container");
  if (logoContainer) {
    const bounds = logoContainer.getBoundingClientRect();
    const { width, left } = bounds;
    const body = document.querySelector("body");
    if (!body) return;
    body.style.setProperty("--logo-end-px", `${width + left}px`);
  }
};
