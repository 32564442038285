<script setup>
 // Libraries
 import { storeToRefs } from 'pinia';

 // Stores
 import { useHeaderStore } from '@/stores/header';
 import { useWindowStore } from '@/stores/window';

 // Composables
 import { setLogoOffset } from '@/composables/helpers/client-only/setLogoOffset';

 const { $bus } = useNuxtApp();

 const { logo, menu } = storeToRefs(useHeaderStore());
 const { innerWidth } = storeToRefs(useWindowStore());

 onMounted(() => {
  setLogoOffset();
 });

 watch(innerWidth, () => {
  setLogoOffset();
 });

 const classes = ref('');
 const scrollLocked = ref(false);
 $bus.on('scroll-lock', (payload) => {
  scrollLocked.value = true;
  if (!payload) return;
  if (payload.menu) classes.value = 'menu-open';
  if (payload.search) classes.value = 'search-open';
 });

 $bus.on('scroll-unlock', () => {
  scrollLocked.value = false;
  classes.value = '';
 });

 const isTnew = computed(() => {
  const { path } = useRoute();
  if (path.includes('tnew')) return true;
  return false;
 });
</script>

<template>
 <!-- <header class="global-header"> -->
 <header class="global-header" :class="`${scrollLocked ? 'locked' : ''} ${classes}`">
  <MessLink href="/" class="logo-container">
   <MessImage v-if="logo.src" :image="logo" :width="325" :lazy="false" />
   <span class="sr-only">Chicago Architecture Center Homepage</span>
  </MessLink>
  <div class="menu-container">
   <GlobalMenu v-if="menu && isTnew" :menu="menu" :data-menu="JSON.stringify(menu)" id="global-menu-tnew" />
   <GlobalMenu v-else-if="menu" :menu="menu" />
  </div>
  <div class="cart-container">
   <GlobalCart />
  </div>
 </header>
</template>

<style lang="scss">
 :root {
  --header-height: 5.5rem;
 }
 .global-header {
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  // add overflow hidden for now
  // overflow: hidden;
  background-color: var(--white);

  &.locked {
   //  width: calc(100% - 15px);
  }

  &.menu-open {
   .menu-container {
    background: var(--gray-100);
   }
  }

  .logo-container {
   background: var(--gray-100);
   height: 100%;
   display: inline-flex;
   align-items: center;
   position: relative;
   padding: 0 4rem 0 4.5rem;

   &:before {
    content: '';
    display: block;
    position: absolute;
    width: calc(var(--vw) * 50);
    right: 100%;
    height: 100%;
    background: var(--gray-100);
   }

   .mess-image {
    --transition: 350ms ease;
    position: relative;
    transition: var(--transition);

    &:after {
     content: '';
     display: block;
     position: absolute;
     top: 0;
     width: 100%;
     height: 100%;
     opacity: 0;
     background: linear-gradient(270deg, var(--gray-100) 0%, rgba(255, 255, 255, 0) 50%, var(--gray-100) 100%);
     transition: var(--transition);
    }
    width: 20rem;
   }

   &:hover {
    .mess-image {
     transform: scale(1.02);
     &:after {
      opacity: 0.5;
     }
    }
   }
  }

  .menu-container {
   height: 100%;
   display: flex;
   align-items: center;
   padding: 0 2rem;
   background: var(--white);
   flex-grow: 1;
   justify-content: flex-end;
  }

  .cart-container {
   // Talk to design about this, because responsive issues
   // margin-right: calc(var(--header-height) * -1);
  }

  // Cart styles
  .cart-button {
   background: var(--red);
   height: var(--header-height);
   width: var(--header-height);
   display: flex;
   align-items: center;
   justify-content: center;
   color: var(--white);
   position: relative;

   --transition: 250ms ease-in-out;

   .mess-svg {
    height: 1.5rem;
    width: 1.5rem;
    transition: var(--transition);
   }

   .counter-container {
    @include absolute-center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--transition);

    .counter {
     margin-top: -2rem;
     margin-right: -0.25rem;
     font-weight: 900;
    }
   }

   &:hover {
    .mess-svg,
    .counter-container {
     transform: scale(1.1);
     opacity: 0.9;
    }
   }
  }

  @media (max-width: $mobile) {
   .menu-container {
    background: var(--gray-100);
   }

   .logo-container {
    padding: 0;
    padding-left: calc(10 * var(--vw));
    .mess-image {
     display: flex;
     align-items: center;
    }
   }
  }
 }
</style>
